<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <v-container
          style="
            padding: 0 0 20px 0;
            margin: auto;
            border-radius: 5px;
            background: white;
          "
        >
          <v-card
            tile
            flat
            style="
              border-bottom: 2px solid rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              position: sticky;
              top: 60px;
              background: white;
              z-index: 5;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
              "
            >
              <v-toolbar-title class="overline">
                <p
                  :style="`font-size:${
                    wWidth > 780 ? '20px' : '3vw'
                  }; margin: 0;`"
                >
                  Formulir Permohonan Cuti
                </p>
              </v-toolbar-title>
            </div>
            <div>
              <v-btn
                type="button"
                rounded
                outlined
                elevation="0"
                color="red"
                class="white--text font-weight-bold ma-2"
                style="font-size: 12px"
                @click="close"
              >
                <v-icon small>mdi-chevron-left</v-icon>
                Kembali
              </v-btn>
            </div>
          </v-card>

          <v-card elevation="0">
            <v-row style="padding: 0 15px" no-gutters>
              <v-col cols="12" md="3" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nama
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      disabled
                      dense
                      outlined
                      :value="form.name"
                      readonly
                      style="height: 45px"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="3" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Perusahaan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      disabled
                      :items="getDropdownPlant || []"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0; height: 45px"
                      :value="form.company_id"
                      readonly
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="3" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Department/ Section
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      disabled
                      :items="dropdown.department"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0; height: 45px"
                      :value="form.department_id"
                      readonly
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="3" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Jabatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      disabled
                      :items="dropdown.position"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0; height: 45px"
                      v-model="form.position_id"
                      readonly
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
            </v-row>
            <div
              v-if="
                getUserProfile.level.find(
                  ({ id }) => id == appLevel.adminMaster
                ) === undefined
              "
            >
              <v-row
                v-if="employeeDetails !== null"
                style="padding: 0 15px; margin-top: 10px"
                no-gutters
              >
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Saldo Cuti Tahun Lalu
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        disabled
                        dense
                        outlined
                        :value="
                          employeeDetails.total_leave_n_1 !== null
                            ? employeeDetails.total_leave_n_1
                            : 0
                        "
                        readonly
                        style="height: 45px"
                      >
                        <template v-slot:append>
                          <div>
                            <p
                              style="
                                margin: 0;
                                padding-top: 5px;
                                font-size: 14px;
                              "
                            >
                              hari
                            </p>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Saldo Cuti Tahun Ini
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        disabled
                        dense
                        outlined
                        :value="
                          employeeDetails.total_leave_n !== null
                            ? employeeDetails.total_leave_n
                            : 0
                        "
                        readonly
                        style="height: 45px"
                      >
                        <template v-slot:append>
                          <div>
                            <p
                              style="
                                margin: 0;
                                padding-top: 5px;
                                font-size: 14px;
                              "
                            >
                              hari
                            </p>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Sisa Cuti
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      style="padding: 0"
                      v-if="
                        employeeDetails.total_leave_n !== null ||
                        employeeDetails.total_leave_n_1 !== null
                      "
                    >
                      <v-text-field
                        disabled
                        dense
                        outlined
                        :value="
                          employeeDetails.total_leave_n +
                          employeeDetails.total_leave_n_1
                        "
                        readonly
                        style="height: 45px"
                      >
                        <template v-slot:append>
                          <div>
                            <p
                              style="
                                margin: 0;
                                padding-top: 5px;
                                font-size: 14px;
                              "
                            >
                              hari
                            </p>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-form
              ref="entryForm"
              @submit.prevent="submit()"
              lazy-validation
              :disabled="loading"
            >
              <v-row style="padding: 15px" no-gutters>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Kategori Tanggal *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.category"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                        v-model="form.category_id"
                        :rules="rules.categoryRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Jenis Cuti *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :item-disabled="disableItem"
                        :items="dropdown.type"
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        style="margin: 0"
                        clearable
                        v-model="form.leave_type"
                        :rules="rules.typeRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col
                  v-if="form.leave_type !== null"
                  cols="12"
                  style="padding: 0"
                >
                  <v-row no-gutters style="padding: 0">
                    <v-col cols="12" md="6" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Tanggal Cuti *
                          </p>
                        </v-col>
                        <v-col
                          v-for="(item, index) in form.request_leave_date"
                          :key="index"
                          cols="12"
                          style="padding: 0"
                        >
                          <v-dialog
                            v-model="item.start_menu"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.start_date"
                                :label="
                                  item.start_date === '' ||
                                  item.start_date === null
                                    ? 'Pilih Tanggal'
                                    : ''
                                "
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                :rules="item.startRules"
                                :disabled="
                                  form.request_leave_date.length > index + 1
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.start_date"
                              no-title
                              scrollable
                            >
                              <!-- :min="
                          form.category_id === 1 ? dateFilter : backDateFilter
                        " -->
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="cancelStartRequest(item, index)"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                :disabled="
                                  item.start_date === '' ||
                                  item.start_date === null
                                "
                                @click="saveStartRequest(item, index)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Sampai tanggal *
                          </p>
                        </v-col>
                        <v-col
                          v-for="(item, index) in form.request_leave_date"
                          :key="index"
                          cols="12"
                          class="d-flex justify-center align-center"
                          style="padding: 0; position: relative"
                        >
                          <v-dialog
                            v-model="item.end_menu"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-if="
                                  item.start_date === '' ||
                                  form.request_leave_date.length > index + 1
                                "
                                v-model="item.end_date"
                                :label="
                                  item.end_date === '' || item.end_date === null
                                    ? 'Pilih Tanggal'
                                    : ''
                                "
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                :rules="item.endRules"
                                disabled
                              >
                              </v-text-field>
                              <v-text-field
                                v-else
                                v-model="item.end_date"
                                :label="
                                  item.end_date === '' || item.end_date === null
                                    ? 'Pilih Tanggal'
                                    : ''
                                "
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                :rules="item.endRules"
                                :disabled="
                                  form.leave_type.is_lock_default_total_leave
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.end_date"
                              no-title
                              scrollable
                            >
                              <!-- :min="
                          form.category_id === 1 ? dateFilter : backDateFilter
                        " -->
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="cancelEndRequest(item, index)"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                :disabled="
                                  item.end_date === '' || item.end_date === null
                                "
                                @click="saveEndRequest(item, index)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                          <div
                            class="d-flex"
                            style="
                              margin-bottom: 30px;
                              position: absolute;
                              right: -25px;
                            "
                          >
                            <div
                              v-if="
                                form.request_leave_date.length === index + 1
                              "
                            >
                              <p
                                v-if="index === 0 && item.start_date !== ''"
                                @click="removeColumn(index)"
                                style="
                                  font-size: 14px;
                                  color: red;
                                  margin: 0 0 0 10px;
                                  cursor: pointer;
                                "
                              >
                                <v-icon color="red" small
                                  >mdi-close-circle</v-icon
                                >
                              </p>
                              <p
                                v-if="index > 0"
                                @click="removeColumn(index)"
                                style="
                                  font-size: 14px;
                                  color: red;
                                  margin: 0 0 0 10px;
                                  cursor: pointer;
                                "
                              >
                                <v-icon color="red" small
                                  >mdi-close-circle</v-icon
                                >
                              </p>
                            </div>
                          </div>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col
                      v-if="
                        form.leave_type.id === 1 ||
                        form.leave_type.id === 4 ||
                        form.leave_type.id === 5
                      "
                      cols="12"
                    >
                      <div
                        style="
                          padding: 10px 10px;
                          font-size: 12px;
                          position: relative;
                          top: -20px;
                        "
                      >
                        <a href="/" @click.prevent="addColumn"
                          >+ tambah kolom tanggal</a
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Jumlah Cuti
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0" class="d-flex">
                          <v-text-field
                            v-model="form.total_requested_leave"
                            dense
                            outlined
                            readonly
                            :rules="rules.totalRequestedRules"
                          >
                            <template v-slot:append>
                              <div>
                                <p
                                  style="
                                    margin: 0;
                                    font-size: 14px;
                                    position: relative;
                                    top: 5px;
                                  "
                                >
                                  hari
                                </p>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Deskripsi / Alasan cuti *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-textarea
                        dense
                        auto-grow
                        outlined
                        v-model="form.leave_reason"
                        :rules="rules.descriptionRules"
                        counter="255"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col
                  v-if="form.leave_type !== null"
                  cols="12"
                  style="padding: 0"
                >
                  <v-row
                    v-if="form.leave_type.id === 4"
                    no-gutters
                    style="padding: 0 10px"
                  >
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Pilih hari libur untuk ganti hari *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-dialog
                        ref="dialog"
                        v-model="changeWorkingModal"
                        :return-value.sync="form.change_working_date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-combobox
                              v-model="form.change_working_date"
                              label="Pilih hari"
                              append-icon="mdi-calendar"
                              readonly
                              outlined
                              multiple
                              clearable
                              chips
                              small-chips
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  @click:close="
                                    data.parent.selectItem(data.item)
                                  "
                                  label
                                  small
                                >
                                  <span class="pr-2">
                                    {{ data.item }}
                                  </span>
                                  <v-icon
                                    small
                                    @click.stop="removeWorkingDate(data)"
                                  >
                                    $delete
                                  </v-icon>
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="form.change_working_date"
                          no-title
                          scrollable
                          multiple
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="changeWorkingModal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(form.change_working_date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="8" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Alamat yang dapat dihubungi ketika cuti
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-textarea
                        dense
                        rows="2"
                        auto-grow
                        outlined
                        v-model="form.leave_address_info"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. Telepon yang dapat dihubungi ketika cuti
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      style="
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0;
                      "
                    >
                      <v-text-field
                        dense
                        outlined
                        v-model="form.leave_phone_no"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0; margin-bottom: 25px">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Delegasi pekerjaan *
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      style="
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0;
                      "
                    >
                      <v-autocomplete
                        dense
                        v-model="form.delegation_employee"
                        clearable
                        deletable-chips
                        small-chips
                        outlined
                        item-text="name"
                        item-id="id"
                        hide-details
                        hide-no-data
                        return-object
                        label="Ketik nama karyawan"
                        :items="items"
                        :search-input.sync="search"
                        :rules="rules.delegationRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>

                <v-col
                  v-if="form.leave_type !== null"
                  cols="12"
                  md="4"
                  style="padding: 0"
                >
                  <div
                    v-if="form.leave_type.is_mandatory_photo"
                    style="padding: 0 10px"
                  >
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Upload Foto *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-file-input
                        ref="photoProfile"
                        placeholder="pilih foto"
                        dense
                        outlined
                        append-icon="mdi-image"
                        prepend-icon=""
                        @change="uploadFoto"
                        :rules="rules.photoRules"
                      ></v-file-input>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
              <div class="d-flex justify-center">
                <v-btn
                  v-if="permission.create_perm == 1"
                  rounded
                  outlined
                  elevation="0"
                  color="green"
                  type="submit"
                  class="white--text font-weight-bold me-5"
                  style="font-size: 12px; width: 150px"
                  :loading="loading"
                >
                  Simpan
                </v-btn>
                <v-btn
                  type="button"
                  rounded
                  outlined
                  elevation="0"
                  color="red"
                  @click="clearForm()"
                  class="white--text font-weight-bold"
                  style="font-size: 12px; width: 150px"
                  :loading="loading"
                >
                  Hapus
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-container>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      DATA NOT FOUND
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import moment from 'moment'
var momentBusinessDays = require('moment-business-days')
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'leavesubmission',
  components: {},
  data() {
    return {
      config: require('../../../services/config.json')[env],
      hrsApi: buildType.apiURL('hrs'),
      upload: buildType.apiURL('upload2'),
      eSanqua: buildType.apiURL('esanqua'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      permission: null,
      appLevel: null,
      userLevel: null,
      isLoadingPage: true,
      isDisableCompanyDropdown: false,
      loading: false,
      dialog: false,
      search: null,
      items: [],
      select: null,
      // dateFilter: new Date(new Date().setDate(new Date().getDate() + 1))
      //   .toISOString()
      //   .substr(0, 10),
      // backDateFilter: '',
      changeWorkingModal: false,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      form: {
        act: 'add',
        name: '',
        company_id: null,
        department_id: null,
        position_id: null,
        section_id: null,
        leave_type: null,
        category_id: 1,
        join_date: '',
        request_leave_date: [
          {
            start_date: '',
            start_menu: false,
            start_ref: 'startRequest0',
            startRules: [],

            end_date: '',
            end_menu: false,
            end_ref: 'endRequest0',
            endRules: []
          }
        ],
        total_requested_leave: 0,
        leave_address_info: '',
        leave_phone_no: '',
        change_working_date: [],
        leave_reason: '',
        delegation_employee: null,
        photo: ''
      },
      wWidth: window.innerWidth,
      rules: {
        typeRules: [],
        categoryRules: [],
        descriptionRules: [],
        totalRequestedRules: [],
        delegationRules: [],
        photoRules: []
      },
      dropdown: {
        company: [],
        department: [],
        position: [],
        section: [],
        type: [],
        category: [
          {
            id: 1,
            name: 'Normal'
          },
          {
            id: 2,
            name: 'Back date'
          }
        ]
      },
      employeeDetails: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant', 'getLeaveDetail'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.$refs.entryForm.reset()
    this.setLeaveDetail(null)
  },
  watch: {
    search(val) {
      if (val !== '') {
        val && val !== this.select && this.querySelections(val)
      } else {
        this.select = null
        this.items = []
      }
    },
    'form.delegation_employee'() {
      if (this.form.delegation_employee === null) {
        this.search = null
        this.select = null
        this.items = []
      }
    },
    'form.leave_type'() {
      this.form.request_leave_date = [
        {
          start_date: '',
          start_menu: false,
          start_ref: 'startRequest0',
          startRules: [],

          end_date: '',
          end_menu: false,
          end_ref: 'endRequest0',
          endRules: []
        }
      ]
      this.form.total_requested_leave = 0
      this.form.change_working_date = []
      // this.dateFilter = new Date(new Date().setDate(new Date().getDate() + 1))
      //   .toISOString()
      //   .substr(0, 10)
      // this.backDateFilter = ''
    },
    'form.category_id'() {
      this.form.request_leave_date = [
        {
          start_date: '',
          start_menu: false,
          start_ref: 'startRequest0',
          startRules: [],

          end_date: '',
          end_menu: false,
          end_ref: 'endRequest0',
          endRules: []
        }
      ]
      this.form.total_requested_leave = 0
      this.form.change_working_date = []
      // this.backDateFilter = ''
    }
  },
  methods: {
    ...mapActions([
      'dropdownPlant',
      'dropdownDepartment',
      'menu_access',
      'getMenuId'
    ]),
    ...mapMutations(['setLeaveDetail']),
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDataForLayout() {
      // if (this.userLevel.is_support) {
      //   this.paramAPI.company_id = 0
      // } else {
      //   this.paramAPI.company_id = Number(
      //     this.getUserProfile.employee.company.plant_id
      //   )
      // }

      this.form.name = this.getUserProfile.employee.name
      this.form.company_id = Number(
        this.getUserProfile.employee.company.plant_id
      )
      this.form.department_id = this.getUserProfile.employee.department_id
      this.form.position_id = this.getUserProfile.employee.level_id
      this.getDetailEmployee(this.getUserProfile.employee_id)
      await this.initDropdown()
      if (this.getLeaveDetail !== null) {
        this.setDetailToForm()
      }
      await this.getHolidayDate()
    },
    async initDropdown() {
      await this.dropdownPlant()
      await this.dropdownDepartment()
      await this.dropdownPosition()
      await this.dropdownType()
    },
    async setDetailToForm() {
      const leave_type = this.dropdown.type.find(
        ({ id }) => id === this.getLeaveDetail.leave_type.id
      )
      this.form.act = 'update'
      this.form.id = this.getLeaveDetail.id
      this.form.category_id = this.getLeaveDetail.leave_category_id
      this.form.leave_type = leave_type
      this.form.leave_reason = this.getLeaveDetail.leave_reason
      this.form.leave_address_info = this.getLeaveDetail.leave_address_info
      this.form.leave_phone_no = this.getLeaveDetail.leave_phone_no
      this.form.change_working_date = this.getLeaveDetail.change_working_date
      this.form.photo = this.getLeaveDetail.photo
      this.form.delegation_employee = this.getLeaveDetail.delegation_employee
      this.search = this.getLeaveDetail.delegation_employee.name
      this.form.total_requested_leave =
        this.getLeaveDetail.total_requested_leave
      setTimeout(async () => {
        const leave_date = []
        for (
          let i = 0;
          i < this.getLeaveDetail.request_leave_date.length;
          i++
        ) {
          leave_date.push({
            start_date: this.getLeaveDetail.request_leave_date[i].start_date,
            start_menu: false,
            start_ref: `startRequest${i}`,
            startRules: [],

            end_date: this.getLeaveDetail.request_leave_date[i].end_date,
            end_menu: false,
            end_ref: `endRequest${i}`,
            endRules: []
          })
        }
        this.form.request_leave_date = leave_date
        await this.totalRequest()
      }, 300)
    },
    addColumn() {
      const self = this
      for (let i = 0; i < this.form.request_leave_date.length; i++) {
        this.form.request_leave_date[i].startRules = [
          (v) => !!v || 'Start date is required'
        ]
        this.form.request_leave_date[i].endRules = [
          (v) => !!v || 'End date is required'
        ]

        setTimeout(async function () {
          if (self.$refs.entryForm.validate()) {
            if (i === 0) {
              self.form.request_leave_date.push({
                start_date: '',
                start_menu: false,
                start_ref: `startRequest${self.form.request_leave_date.length}`,
                startRules: [],

                end_date: '',
                end_menu: false,
                end_ref: `endRequest${self.form.request_leave_date.length}`,
                endRules: []
              })
            }
          }
        })
      }
    },
    removeColumn(index) {
      // this.setBeforeDateFilter(index)
      if (this.form.request_leave_date.length > 1) {
        this.form.request_leave_date.splice(index, 1)
        this.totalRequest()
      } else {
        this.form.request_leave_date[index].start_date = ''
        this.form.request_leave_date[index].end_date = ''
        this.form.request_leave_date[index].startRules = []
        this.form.request_leave_date[index].endRules = []
        this.form.total_requested_leave = 0
      }
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    querySelections(v) {
      axios
        .get(`${this.hrsApi}employee/dropdown_on_leave?keyword=${v}`)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.items = res.data.data)
          }
          return (this.items = [])
        })
        .catch((err) => {
          this.items = []
          return console.log(err)
        })
    },
    async totalRequest() {
      this.rules.totalRequestedRules = []
      this.form.total_requested_leave = 0
      for (let i = 0; i < this.form.request_leave_date.length; i++) {
        await axios
          .post(
            `${this.hrsApi}employee/leave/calculate_business_date`,
            this.form.request_leave_date[i]
          )
          .then((res) => {
            console.log(res)
            this.loading = false
            if (res.data.status_code === '00') {
              return (this.form.total_requested_leave =
                this.form.total_requested_leave + res.data.business_date_count)
            } else {
              if (res.data.status_msg === 'Parameter value has problem') {
                return this.showMsgDialog(
                  'error',
                  'Invalid input, Parameter value has problem',
                  false
                )
              } else {
                return this.showMsgDialog('error', res.data.status_msg, false)
              }
            }
          })
          .catch((err) => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
            return (this.loading = false)
          })
      }
    },
    clearForm(val) {
      // reset error validation
      // this.$refs.entryForm.resetValidation()

      // // reset form
      this.$refs.entryForm.reset()

      this.rules.descriptionRules = []
      this.rules.typeRules = []
      this.rules.categoryRules = []
      this.rules.totalRequestedRules = []
      this.rules.delegationRules = []
      this.rules.photoRules = []

      for (let i = 0; i < this.form.request_leave_date.length; i++) {
        this.form.request_leave_date[i].startRules = []
        this.form.request_leave_date[i].endRules = []
      }
    },
    submit() {
      const self = this
      this.rules.descriptionRules = [(v) => !!v || 'Description is required']
      this.rules.typeRules = [(v) => !!v || 'Leave type is required']
      this.rules.categoryRules = [(v) => !!v || 'Category type is required']
      this.rules.totalRequestedRules = [
        (v) => !!v || 'Total request is required'
      ]
      this.rules.delegationRules = [(v) => !!v || 'Delegation is required']

      for (let i = 0; i < this.form.request_leave_date.length; i++) {
        this.form.request_leave_date[i].startRules = [
          (v) => !!v || 'Start date is required'
        ]
        this.form.request_leave_date[i].endRules = [
          (v) => !!v || 'End date is required'
        ]
      }
      if (this.form.leave_type !== null) {
        if (this.form.leave_type.is_mandatory_photo) {
          this.rules.photoRules = [(v) => !!v || 'Photo is required']
        }
      }

      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const leave_date = []
      for (let i = 0; i < this.form.request_leave_date.length; i++) {
        leave_date.push({
          start_date: this.form.request_leave_date[i].start_date,
          end_date: this.form.request_leave_date[i].end_date
        })
      }
      const form = {
        act: this.form.act,
        leave_type_id: this.form.leave_type.id,
        leave_category_id: this.form.category_id,
        leave_type_name: this.form.leave_type.name,
        request_leave_date: leave_date,
        total_requested_leave: this.form.total_requested_leave,
        leave_reason: this.form.leave_reason,
        leave_address_info: this.form.leave_address_info,
        leave_phone_no: this.form.leave_phone_no,
        delegation_employee_id:
          this.form.delegation_employee !== null
            ? this.form.delegation_employee.id
            : null,
        change_working_date: this.form.change_working_date,
        photo: this.form.photo
      }
      if (form.act === 'update') {
        Object.assign(form, {
          id: this.form.id
        })
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/leave/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.clearForm()
            this.showMsgDialog('success', res.data.status_msg, false).then(
              (response) => {
                setTimeout(() => {
                  this.setLeaveDetail(null)
                  this.$router.push(
                    `/hr/leave/detail/${
                      this.form.act === 'add'
                        ? res.data.created_id
                        : this.form.id
                    }`
                  )
                }, 500)
              }
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    close() {
      this.$refs.entryForm.reset()
      this.setLeaveDetail(null)
      if (this.prevRoute !== undefined) {
        this.$router.push(this.prevRoute.path)
      } else {
        this.$router.push('/hr/leave')
      }
    },

    uploadFoto(event) {
      if (event !== null) {
        this.loading = true
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'photo.leave')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then((res) => {
            console.log(res)
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.form.photo = res.data.data.name
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
            this.loading = false
          })
          .catch((err) => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
            this.loading = false
          })
      } else {
        this.form.photo = ''
      }
    },
    async dropdownDepartment() {
      await axios
        .get(`${this.hrsApi}master/universal/department/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            this.dropdown.section = res.data.data
            return (this.dropdown.department = res.data.data)
          }
          this.dropdown.section = []
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          this.dropdown.section = []
          return (this.dropdown.department = [])
        })
    },

    async dropdownPosition() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.position = res.data.data)
          }
          return (this.dropdown.position = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.position = [])
        })
    },

    async dropdownType() {
      await axios
        .get(`${this.hrsApi}master/universal/leavetype/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.type = res.data.data)
          }
          return (this.dropdown.type = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.type = [])
        })
    },
    async getDetailEmployee(val) {
      axios
        .get(`${this.hrsApi}employee/detail/${val}`)
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            this.employeeDetails = res.data.data
          }
          return null
        })
        .catch((err) => {
          return console.log(err)
        })
    },
    // setNextDateFilter(date) {
    //   const selectedDate = new Date(date)
    //   const nextDate = new Date(
    //     new Date(date).setDate(selectedDate.getDate() + 1)
    //   )
    //   this.dateFilter = nextDate.toISOString().substr(0, 10)
    //   this.backDateFilter = nextDate.toISOString().substr(0, 10)
    // },
    // setBeforeDateFilter(index) {
    //   let selectedDate = null
    //   // date filter if leave = normal
    //   if (this.form.category_id === 1) {
    //     if (index > 0) {
    //       selectedDate = new Date(
    //         this.form.request_leave_date[index - 1].end_date
    //       )
    //     } else {
    //       selectedDate = new Date()
    //     }
    //     const nextDate = new Date(
    //       new Date().setDate(selectedDate.getDate() + 1)
    //     )
    //     // this.dateFilter = nextDate.toISOString().substr(0, 10)
    //   }

    //   // date filter if leave = backdate
    //   if (this.form.category_id === 2) {
    //     if (index > 0) {
    //       const date = this.form.request_leave_date[index - 1].end_date
    //       selectedDate = new Date(date)
    //       const nextDate = new Date(
    //         new Date(date).setDate(selectedDate.getDate() + 1)
    //       )
    //       this.backDateFilter = nextDate.toISOString().substr(0, 10)
    //     } else {
    //       this.backDateFilter = ''
    //     }
    //   }
    // },
    async saveStartRequest(item, index) {
      // this.setNextDateFilter(item.start_date)
      item.end_date = item.start_date
      item.start_menu = false
      let convert = ''
      if (this.form.leave_type.unit_default_total_leave === 2) {
        convert = moment(item.start_date)
          .add(this.form.leave_type.default_total_leave, 'months')
          .add(1, 'days')
      } else {
        convert = momentBusinessDays(item.start_date, 'YYYY-MM-DD').businessAdd(
          this.form.leave_type.default_total_leave
        )._d
      }
      this.form.request_leave_date[index].end_date = convert
        .toISOString('id')
        .substr(0, 10)

      await this.totalRequest()
    },
    cancelStartRequest(item, index) {
      item.start_menu = false
    },
    async saveEndRequest(item, index) {
      // this.setNextDateFilter(item.end_date)
      await this.totalRequest()
      item.end_menu = false
    },
    cancelEndRequest(item, index) {
      item.end_menu = false
    },
    removeWorkingDate(item) {
      this.form.change_working_date.splice(item.index, 1)
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    disableItem(item) {
      if (this.getUserProfile.employee.join_year_num >= 1) {
        return item.id === 5
      } else {
        if (item.id < 5) {
          if (item.id === 3) {
            return false
          }
          return true
        } else {
          return false
        }
      }
    },
    async getHolidayDate() {
      var localHolidayDate = []
      var workingDays = []
      if (
        this.getUserProfile.employee.weekend_day != null &&
        !this.getUserProfile.employee.is_shift
      ) {
        if (this.getUserProfile.employee.weekend_day.length != 0) {
          for (let i = 0; i <= 6; i++) {
            workingDays.push(i)
          }
          for (
            let i = 0;
            i < this.getUserProfile.employee.weekend_day.length;
            i++
          ) {
            if (
              workingDays.indexOf(
                this.getUserProfile.employee.weekend_day[i]
              ) !== -1
            ) {
              workingDays.splice(
                workingDays.indexOf(
                  this.getUserProfile.employee.weekend_day[i]
                ),
                1
              )
            }
          }
        } else {
          workingDays = [0, 1, 2, 3, 4, 5, 6]
        }
      } else {
        workingDays = [0, 1, 2, 3, 4, 5, 6]
      }

      await axios
        .get(
          `${this.hrsApi}publicholiday/list?keyword=&order_by=date&order_type=asc&offset=0&limit=10000`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            for (let i = 0; i < res.data.data.length; i++) {
              localHolidayDate.push(res.data.data[i].date)
            }
          }
          return null
        })
        .catch((err) => {
          return console.log(err)
        })

      momentBusinessDays.updateLocale('us', {
        holidays: localHolidayDate,
        holidayFormat: 'YYYY-MM-DD',
        workingWeekdays: workingDays
      })
    }
  }
}
</script>
